<template lang='pug'>
	div
		pw-alert(v-for='i in alerts' :key='i.text' :variant='i.variant' :btn='i.btn' :text='i.text' :textFn='i.textFn' :badge="i.badge")
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import PwAlert from '@/components/Form/PwAlert'

export default {
	name    : 'ProjectAlerts',
	components: {
		PwAlert
	},
	data: () => ({
		alerts: [],
		ruAcc: null,
	}),
	computed: {
		...mapGetters({
			item: 'project/opened',
			accountListActiveRemoved: 'finance/accountsActiveRemoved',
		}),
		accountListFiltered () {
			return _.sortBy(this.accountListActiveRemoved, a => {
				return a.isRUB() && a.status == 'ACTIVE' ? 0 : 1;
			})
		},
	},
	methods : {
		...mapActions({
			activate: 'project/activate',
		}),
		setAlerts () {
			this.alerts = [];

			if ( !this.item ) return this.alerts;

			if ( this.item.hasSomeResourcesBotIssueNoTags() && this.$route.name != 'project_resources' )
				this.alerts.push({variant: 'warning', text: this.$t('plan.noRights'), btn: {fn: this.goToResources, title: this.$t('button.goTo')}, badge: '🤖'})
			if ( this.me && this.item && this.item.isBlocked() )
				this.alerts.push({variant: 'danger', text: this.$t('project.blockedProject'), btn: {fn: this.openModalSupport, title: this.$t('button.write')}, badge: '🚧'})
			if ( this.item.isArchived() )
				this.alerts.push({variant: 'info', text: this.$t('plan.projectArchived'), btn: {fn: this.unarchive, title: this.$t('button.extract')}, badge: '🗄️'})
			if ( this.accountListFiltered[0] && this.accountListFiltered[0].isTinkoff() && !this.accountListFiltered[0].hasCard())
				this.alerts.push({variant: 'warning', text: this.$t('finance.noCard'), btn: {fn: this.goToFinance, title: this.$t('forms.add')}, badge: '💳'})

			if ( this.item && this.item.isNeuro() && this.item.bot && this.item.bot.isError())
				this.alerts.push({variant: 'danger', text: this.$t('alerts.toCorrectBot'), btn: {fn: this.goToDocsBot, title: this.$t('errors.toCorrect')}, badge: '🚨'})
			if ( this.item && this.item.isNeuro() && this.item.bot && !this.item.bot.image )
				this.alerts.push({variant: 'warning', text: this.$t('alerts.changeImageBot'), btn: {fn: this.goToSettingsBot, title: this.$t('forms.change')}, badge: '🤖 '})
		},
		goToResources () { this.$router.push({name: 'project_resources'}) },
		goToFinance () { this.$router.push({name: 'finance'}) },
		goToTariffs () { this.$router.push({name: 'project_plans'}) },
		goToAccounts () { this.$router.push({name: 'project_accounts'}) },
		goToDocsBot () { window.open('https://docs.paywall.pw/rus', '_blank') },
		goToSettingsBot () { this.$router.push({name: 'settings_bot'}) },
		goToWrite () { window.open(this.isLocaleRu ? 'http://t.me/paywallsupport' : 'http://t.me/paywallsupport_en', '_blank'); },
		openModalSupport() { this.$bvModal.show('modal-support') },
		unarchive () {
			this.activate({id: this.item.id})
				.then( i => {
					this.$store.commit('project/activate', this.item.id)
					this.$notify("success", this.$t('success.projectActivated'));
				})
		},
	},
	created () {
		this.setAlerts();
	},
	watch : {
		item: {
			handler (nv, ov) {
				this.setAlerts();
			},
			deep: true
		},
	},
}
</script>
