<template lang='pug'>
	.card-basic.advantages(v-if="advantages.length > 0" :class="advantagesClass")
		h2.bold.mb-4 {{ title }}
		p.b3.mb-3.text-grey(v-if="advantagesClass == 'advantages--resources'") {{ $t('resources.noResources.textTop') }}
		ul.d-flex.list-unstyled.flex-column.m-0.flex-wrap.position-relative.advantages__list
			li.d-flex.align-items-center.mb-4.advantages__item(v-for="(item, i) in advantages" :key="`advantage-${i}`")
				.d-flex.justify-content-center.align-items-center.rounded-circle.p-2.mr-4.position-relative.z-index-1.advantages__icon
					img(src="/assets/img/icons/check.svg")
				p.m-0.b1.advantages__text(v-html="item.text")
		p.b3.text-grey.pb-4.pb-md-0(v-if="advantagesClass == 'advantages--resources'") {{ $t('resources.noResources.textBottom') }}
</template>

<script>
export default {
	name: 'Advantages',
	props: {
		title: {
			default : '',
			type : String
		},
		advantages: {
			default : [],
			type : Array
		},
		advantagesClass: {
			default : '',
			type : String
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.advantages {
	padding: 40px 20px 12px 20px;

	@include respond-below(sm) {
		padding: 20px 16px 0 16px;
	}

	&__list {
		height: 194px;

		&::before {
			content: "";
			position: absolute;
			top: 20px;
			left: 16px;
			width: 1px;
			height: 130px;
			background-image: url('/assets/img/icons/line.svg');

			@include respond-below(sm) {
				top: 6px;
				height: 85%;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 30px;
			left: calc(50% + 16px);
			width: 1px;
			height: 90px;
			background-image: url('/assets/img/icons/line.svg');

			@include respond-below(sm) {
				content: none;
			}
		}

		@include respond-below(sm) {
			height: auto;
		}
	}

	&__item {
		width: 50%;

		@include respond-below(sm) {
			width: 100%;
		}
	}

	&__icon {
		z-index: 1;
		max-width: 35px;
		min-width: 35px;
		max-height: 35px;
		min-height: 35px;
		background-color: var(--foreground-back);
		box-shadow: var(--box-shadow-icon);
	}

	&__text {
		width: 100%;
		max-width: 280px;

		@include respond-below(sm) {
			width: auto;
			max-width: 100%;
		}
	}

	&--promo-codes {
		.advantages__list {
			height: 220px;

			@include respond-below(sm) {
				height: auto;
			}
		}
	}

	&--resources {
		padding: 30px 20px 12px 20px;

		.advantages__list {
			height: auto;

			&::before {
				top: 20px;
				left: 16px;
				height: 70px;
			}

			&::after {
				content: none;
			}
		}
	}
}
</style>
